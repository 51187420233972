import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { create, getById, update } from "../../../store/photoGallery/index";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { fileUpload } from "../../../store/fileUpload/index";

function AddPhotoGallery() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data } = useSelector((state) => state?.photoGalleryReducer);
  const { loading: fileloading } = useSelector(
    (state) => state?.fileUploadReducer
  );
  useEffect(() => {
    if (id) {
      dispatch(getById(id));
    }
  }, [id]);

  useEffect(() => {
    if (data && id) {
      formik.setFieldValue("image", data?.image);
      formik.setFieldValue("active", data?.active);
      // formik.setFieldValue("position", data?.position);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      image: "",
      active: true,
      // position: 0,
    },
    validationSchema: Yup.object({
      image: Yup.string().required("Required"),
      // position: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      let payload = {
        image: values.image,
        active: values.active,
        // position: values?.position,
        navigate: navigate,
      };
      if (id) {
        payload["id"] = id;
        dispatch(update(payload));
      } else {
        dispatch(create(payload));
      }
    },
  });

  const handalFileUpload = async (file) => {
    try {
      const { files } = file.target;
      let data = Array.from(files);
      dispatch(fileUpload(data[0]))
        .unwrap()
        .then((resp) => {
          if (resp.status) {
            formik.setFieldValue("image", resp.data);
          }
        })
        .catch((error) => {});
    } catch (error) {}
  };

  return (
    <>
      {(loading || fileloading) && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/photo-gallery/1">Photo Gallery</Link>
            </span>
            <span> / {id ? data?.name : "New"}</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Image <span className="text-danger">*</span>
                    </label>
                    <br />
                    <input
                      name="image"
                      type="file"
                      onChange={(file) => {
                        handalFileUpload(file);
                      }}
                      onBlur={formik.handleBlur}
                    />
                  </div>
                  {formik.touched.image && formik.errors.image ? (
                    <div style={{ color: "red" }} className="mb-3">
                      {formik.errors.image}
                    </div>
                  ) : null}
                  {formik?.values?.image && (
                    <div className="col-md-12 mt-4 mb-4 pl-0">
                      <img src={formik?.values?.image} width={"100%"} />
                    </div>
                  )}
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Active <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"active"}
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue("active", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Active
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  {id ? "Update" : "Create"}
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    formik?.resetForm();
                    navigate(`/admin/photo-gallery/1`);
                  }}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddPhotoGallery;
