import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const get = createAsyncThunk("scheduleMeeting/get", async (page) => {
  try {
    const response = await httpService.get(
      `/api/interest?page=${page}&scheduleMeeting=true`
    );
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const editscheduleMeeting = createAsyncThunk(
  "scheduleMeeting/editscheduleMeeting",
  async (data) => {
    try {
      const response = await httpService.put(
        `/api/interest/` + data.id,
        {},
        data
      );
      return await response.data;
    } catch (error) {
      toast.error(error.message);
      throw new Error(error.message);
    }
  }
);

export const scheduleMeetingSlice = createSlice({
  name: "ScheduleMeeting",
  initialState: {
    list: [],
    loading: false,
    paginate: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(get.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(get.fulfilled), (state, action) => {
      state.list = action.payload?.data?.docs;
      const paginate = {
        hasNextPage: action?.payload?.data?.hasNextPage,
        hasPrevPage: action?.payload?.data?.hasPrevPage,
        limit: action?.payload?.data?.limit,
        nextPage: action?.payload?.data?.nextPage,
        page: action?.payload?.data?.page,
        pagingCounter: action?.payload?.data?.pagingCounter,
        prevPage: action?.payload?.data?.prevPage,
        totalDocs: action?.payload?.data?.totalDocs,
        totalPages: action?.payload?.data?.totalPages,
      };
      state.paginate = paginate;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(get.rejected), (state, action) => {
      state.loading = false;
    });
  },
});

export default scheduleMeetingSlice.reducer;
