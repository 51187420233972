import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";
import * as Yup from "yup";
import { all as allCaste } from "../../../store/caste";
import { all as allLanguage } from "../../../store/language";
import { all as allReligion } from "../../../store/religion";

function SocioReligious({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.userReducer);
  const { list: religion } = useSelector((state) => state?.religionReducer);
  const { list: caste } = useSelector((state) => state?.casteReducer);
  const { list: language } = useSelector((state) => state?.languageReducer);

  useEffect(() => {
    dispatch(allReligion());
    dispatch(allCaste());
    dispatch(allLanguage());
  }, []);

  useEffect(() => {
    if (userid) {
      formik.setFieldValue(
        "religion",
        userData?.religion?._id ? userData?.religion?._id : ""
      );
      formik.setFieldValue(
        "caste",
        userData?.caste?._id ? userData?.caste?._id : ""
      );
      formik.setFieldValue("subCast", userData.subCast ? userData.subCast : "");
      formik.setFieldValue(
        "motherTongue",
        userData?.motherTongue?._id ? userData?.motherTongue?._id : ""
      );
      formik.setFieldValue(
        "maritalStatus",
        userData.maritalStatus ? userData.maritalStatus : ""
      );
      formik.setFieldValue("manglik", userData.manglik ? userData.manglik : "");
      formik.setFieldValue(
        "placeOfBirth",
        userData.placeOfBirth ? userData.placeOfBirth : ""
      );
      formik.setFieldValue(
        "timeOfBirth",
        userData.timeOfBirth ? userData.timeOfBirth : ""
      );

      formik.setFieldValue(
        "haveChildren",
        userData.haveChildren ? userData.haveChildren : ""
      );
      formik.setFieldValue("son", userData.son ? userData.son : "");
      formik.setFieldValue(
        "daughter",
        userData.daughter ? userData.daughter : ""
      );
      formik.setFieldValue("living", userData.living ? userData.living : false);
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      religion: "",
      caste: "",
      subCast: "",
      motherTongue: "",
      maritalStatus: "",
      haveChildren: "",
      son: "",
      daughter: "",
      living: false,
      manglik: "",
      placeOfBirth: "",
      timeOfBirth: "",
    },
    validationSchema: Yup.object({
      religion: Yup.string().required("Religion is a required"),
      caste: Yup.string().required("Caste is a required"),
      motherTongue: Yup.string().required("Mother tongue is a required"),
      maritalStatus: Yup.string().required("Marital status is a required"),
      manglik: Yup.string().required("Manglik is a required"),
      placeOfBirth: Yup.string().required("Place of birth is a required"),
      timeOfBirth: Yup.string().required("Time of birth is a required"),
    }),
    onSubmit: (values) => {
      const temp_obj = { ...values, navigate: navigate, id: userid };
      dispatch(update(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Socio Religious</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Religion <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="religion"
                    value={formik.values.religion}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Religion</option>
                    {religion.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.religion && formik.touched.religion && (
                    <p className="text-danger">{formik.errors.religion}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Caste <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="caste"
                    value={formik.values.caste}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Caste</option>
                    {caste.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.caste && formik.touched.caste && (
                    <p className="text-danger">{formik.errors.caste}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Sub Cast</label>
                  <input
                    type="text"
                    className="form-control"
                    name="subCast"
                    value={formik.values.subCast}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Mother Tongue <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="motherTongue"
                    value={formik.values.motherTongue}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Language</option>
                    {language.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.motherTongue &&
                    formik.touched.motherTongue && (
                      <p className="text-danger">
                        {formik.errors.motherTongue}
                      </p>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Marital Status <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="maritalStatus"
                    value={formik.values.maritalStatus}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Marital Status</option>
                    <option value="Never Married">Never Married</option>
                    <option value="Separated">Separated</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                    <option value="Awaiting Divorce">Awaiting Divorce</option>
                  </select>
                  {formik.errors.maritalStatus &&
                    formik.touched.maritalStatus && (
                      <p className="text-danger">
                        {formik.errors.maritalStatus}
                      </p>
                    )}
                </div>

                {formik?.values?.maritalStatus &&
                  formik?.values?.maritalStatus !== "Never Married" && (
                    <>
                      <div className="row mt-2">
                        <div className="col-md-6 form-group">
                          <label className="lb">Have Children</label>
                          <select
                            name="haveChildren"
                            className="form-control"
                            value={formik.values.haveChildren}
                            onChange={formik.handleChange}
                          >
                            <option value="">Select</option>
                            <option value="yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                      </div>
                      {formik?.values?.haveChildren &&
                        formik?.values?.haveChildren === "yes" && (
                          <div className="row">
                            <div className="col-md-6 form-group">
                              <label className="lb">Son</label>
                              <input
                                type="number"
                                className="form-control"
                                name="son"
                                value={formik?.values?.son}
                                onChange={formik.handleChange}
                              />
                            </div>
                            <div className="col-md-6 form-group">
                              <label className="lb">Daughter </label>
                              <input
                                type="number"
                                className="form-control"
                                name="daughter"
                                value={formik?.values?.daughter}
                                onChange={formik.handleChange}
                              />
                            </div>

                            <div className="col-md-12 form-group">
                              <div className="form-group">
                                <label>Living togerther </label>
                                <div className="checkbox c-checkbox">
                                  <label className="mb-1">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      name={"living"}
                                      checked={formik?.values?.living}
                                      onChange={(e) => {
                                        formik.setFieldValue(
                                          "living",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                    <span className="fa fa-check"></span>Is
                                    living togerther
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </>
                  )}
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Manglik <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="manglik"
                    value={formik.values.manglik}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value="">Select Manglik</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Anshik">Anshik</option>
                    <option value="Do not know">Do not know</option>
                  </select>
                  {formik.errors.manglik && formik.touched.manglik && (
                    <p className="text-danger">{formik.errors.manglik}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Place Of Birth <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="placeOfBirth"
                    name="placeOfBirth"
                    value={formik.values.placeOfBirth}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.placeOfBirth &&
                    formik.touched.placeOfBirth && (
                      <p className="text-danger">
                        {formik.errors.placeOfBirth}
                      </p>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Time Of Birth (hh/mm) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="timeOfBirth"
                    name="timeOfBirth"
                    value={formik.values.timeOfBirth}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.timeOfBirth && formik.touched.timeOfBirth && (
                    <p className="text-danger">{formik.errors.timeOfBirth}</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default SocioReligious;
