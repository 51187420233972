import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";
import * as Yup from "yup";
import { all as allQualification } from "../../../store/qualification";
import { all as allOccupation } from "../../../store/occupation";

function Education({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.userReducer);
  const { list: occupation } = useSelector((state) => state?.occupationReducer);
  const { list: qualification } = useSelector(
    (state) => state?.qualificationReducer
  );

  useEffect(() => {
    dispatch(allQualification());
    dispatch(allOccupation());
  }, []);

  useEffect(() => {
    if (userid) {
      formik.setFieldValue(
        "qualification",
        userData?.qualification?._id ? userData?.qualification?._id : ""
      );
      formik.setFieldValue(
        "occupation",
        userData?.occupation?._id ? userData?.occupation?._id : ""
      );
      formik.setFieldValue("jobType", userData.jobType ? userData.jobType : "");
      formik.setFieldValue(
        "companyName",
        userData.companyName ? userData.companyName : ""
      );
      formik.setFieldValue("salary", userData.salary ? userData.salary : "");
      formik.setFieldValue(
        "jobTotalExperience",
        userData.jobTotalExperience ? userData.jobTotalExperience : ""
      );
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      qualification: "",
      occupation: "",
      companyName: "",
      jobType: "",
      jobTotalExperience: "",
      salary: "",
    },
    validationSchema: Yup.object({
      qualification: Yup.string().required("Qualification is a required"),
      occupation: Yup.string().required("Occupation is a required"),
      jobType: Yup.string().required("Job type is a required"),
    }),
    onSubmit: (values) => {
      const temp_obj = { ...values, navigate: navigate, id: userid };
      dispatch(update(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Job & Education</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Qualification <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="qualification"
                    value={formik.values.qualification}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Qualification</option>
                    {qualification.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.qualification &&
                    formik.touched.qualification && (
                      <p className="text-danger">
                        {formik.errors.qualification}
                      </p>
                    )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Occupation <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="occupation"
                    value={formik.values.occupation}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Occupation</option>
                    {occupation.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.occupation && formik.touched.occupation && (
                    <p className="text-danger">{formik.errors.occupation}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Job Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="jobType"
                    value={formik.values.jobType}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Job Type</option>
                    <option value="MNC">MNC</option>
                    <option value="Central Government">
                      Central Government
                    </option>
                    <option value="State Government">State Government</option>
                    <option value="Private Sector">Private Sector</option>
                    <option value="Not Working">Not Working</option>
                    <option value="Other">Other</option>
                  </select>
                  {formik.errors.jobType && formik.touched.jobType && (
                    <p className="text-danger">{formik.errors.jobType}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Company name</label>
                  <input
                    type="text"
                    className="form-control"
                    id="companyName"
                    name="companyName"
                    value={formik.values.companyName}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Experience (Years)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="jobTotalExperience"
                    value={formik.values.jobTotalExperience}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Annual Income (Yearly)</label>
                  <input
                    type="text"
                    className="form-control"
                    name="salary"
                    value={formik.values.salary}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Education;
