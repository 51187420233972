import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";
import * as Yup from "yup";

function BasicInformation({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.userReducer);

  useEffect(() => {
    if (userid) {
      formik.setFieldValue("name", userData?.name ? userData?.name : "");
      formik.setFieldValue("email", userData?.email ? userData?.email : "");
      formik.setFieldValue(
        "mobileNumber",
        userData?.mobileNumber ? userData?.mobileNumber : ""
      );
      formik.setFieldValue(
        "active",
        userData?.active ? userData?.active : false
      );
      formik.setFieldValue(
        "verify",
        userData?.verify ? userData?.verify : false
      );
      formik.setFieldValue(
        "profileHighlighter",
        userData?.profileHighlighter ? userData?.profileHighlighter : false
      );
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNumber: "",
      active: true,
      verify: false,
      profileHighlighter: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Full name is a required")
        .min(6),
      email: Yup.string()
        .required("Email is a required")
        .email("Email must be a valid email"),
      mobileNumber: Yup.string().required("Phone is a required"),
    }),
    onSubmit: (values) => {
      const temp_obj = {
        email: values.email,
        name: values.name,
        mobileNumber: values.mobileNumber,
        active: values.active,
        verify: values.verify,
        profileHighlighter: values.profileHighlighter,
        navigate: navigate,
        id: userid,
      };
      dispatch(update(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Basic Info</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Full Name <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="name"
                    type="text"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.name && formik.errors.name ? (
                    <div style={{ color: "red" }}>{formik.errors.name}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="email"
                    type="text"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    disabled
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Phone <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    name="mobileNumber"
                    type="text"
                    value={formik.values.mobileNumber}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.mobileNumber && formik.errors.mobileNumber ? (
                    <div style={{ color: "red" }}>
                      {formik.errors.mobileNumber}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Active <span className="text-danger">*</span>
                  </label>
                  <div className="checkbox c-checkbox">
                    <label className="mb-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name={"active"}
                        checked={formik.values.active}
                        onChange={(e) => {
                          formik.setFieldValue("active", e.target.checked);
                        }}
                      />
                      <span className="fa fa-check"></span>Is Active
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Verify Profile <span className="text-danger">*</span>
                  </label>
                  <div className="checkbox c-checkbox">
                    <label className="mb-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name="verify"
                        checked={formik.values.verify}
                        onChange={(e) => {
                          formik.setFieldValue("verify", e.target.checked);
                        }}
                      />
                      <span className="fa fa-check"></span>Is Verify Profile
                    </label>
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="form-group">
                  <label>
                    Profile Highlighter <span className="text-danger">*</span>
                  </label>
                  <div className="checkbox c-checkbox">
                    <label className="mb-1">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        name={"profileHighlighter"}
                        checked={formik.values.profileHighlighter}
                        onChange={(e) => {
                          formik.setFieldValue(
                            "profileHighlighter",
                            e.target.checked
                          );
                        }}
                      />
                      <span className="fa fa-check"></span>Is Profile
                      Highlighter
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default BasicInformation;
