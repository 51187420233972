import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";

function Hobbies({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hobbies, setHobbies] = useState([]);
  const { loading } = useSelector((state) => state?.userReducer);

  useEffect(() => {
    if (userid) {
      if (userData && userData.hobbies && userData.hobbies.length) {
        setHobbies(userData.hobbies);
      }
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      hobbies: "",
    },
    onSubmit: (values) => {
      const temp_obj = { hobbies: hobbies, navigate: navigate, id: userid };
      dispatch(update(temp_obj));
    },
  });

  const addHobbie = () => {
    setHobbies((hobbies) => {
      return [...hobbies, ""];
    });
  };

  const handleHobbieChange = (e) => {
    e.preventDefault();
    const index = e.target.id;
    setHobbies((s) => {
      const newArr = s.slice();
      newArr[index] = e.target.value;
      return newArr;
    });
  };

  const removeHobbie = (indx) => {
    var array = [...hobbies];
    array.splice(indx, 1);
    setHobbies(array);
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Hobbies</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-3 form-group text-end">
                <button
                  className="btn  btn-primary mb-2"
                  type="button"
                  onClick={addHobbie}
                >
                  <i className="fa fa-plus mr-2"></i>
                  Add Hobby
                </button>
              </div>
              <br />
              <div className="col-md-12 form-group">
                <label className="h4 mb-0">Hobbies</label>
              </div>
              {hobbies.map((item, i) => {
                return (
                  <div
                    key={i}
                    className="row w-100"
                    style={{ padding: "0 0 15px 15px" }}
                  >
                    <div className="col-md-6 form-group">
                      <input
                        onChange={handleHobbieChange}
                        id={i}
                        type="text"
                        value={item}
                        className="form-control"
                        key={i}
                      />
                    </div>
                    <div className="col-md-4">
                      <i
                        className="fa fa-trash text-danger mt-2"
                        style={{ fontSize: 24, cursor: "pointer" }}
                        onClick={() => removeHobbie(i)}
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                );
              })}
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Hobbies;
