import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { create } from "../../../store/payment";
import Select from "react-select";
import * as Yup from "yup";
import { get, update } from "../../../store/user";
import moment from "moment";

var plan = {
  "3months": "Gold",
  "6months": "Diamond",
  "12months": "Platinum",
  tillumarry: "Till U Marry",
};

var tillValid = {
  "3months": 3,
  "6months": 6,
  "12months": 12,
  tillumarry: "Till U Marry",
};

function NewPayment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, list: userList } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(get(1));
  }, []);

  const getValidTill = (date, months) => {
    if (months === "Till U Marry") {
      return null;
    }
    if (date) {
      var newDate = moment(new Date(date)).add(months, "M");
      return new Date(newDate);
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      user: "",
      type: "",
    },
    validationSchema: Yup.object({
      user: Yup.string().required("Required"),
      type: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      console.log();
      const temp_obj = {
        ...values,
        navigate: navigate,
        planName: plan[values.type],
        start: new Date(),
        end: getValidTill(new Date(), tillValid[values.type]),
      };
      dispatch(create(temp_obj))
        .unwrap()
        .then((data) => {
          if (data?.status) {
            dispatch(
              update({
                planStatus: "premium",
                lastPlan: data.data._id,
                id: values.user,
                msg: true,
              })
            );
            navigate("/admin/payment/1");
          }
        });
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/payment/1">Payment</Link>
            </span>
            <span>/ New</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row" style={{ padding: "0 0 15px 0" }}>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user"
                      options={userList || []}
                      onChange={(data) => {
                        formik.setFieldValue("user", data?._id);
                      }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select.."
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                    />
                    {formik.touched.user && formik.errors.user ? (
                      <div style={{ color: "red" }}>{formik.errors.user}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Plan Type <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control"
                      name="type"
                      value={formik.values.type}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    >
                      <option value={""}>Select Plan Type</option>
                      {Object.entries(plan).map(([key, value]) => {
                        return <option value={key}>{value}</option>;
                      })}
                    </select>
                    {formik.touched.type && formik.errors.type ? (
                      <div style={{ color: "red" }}>{formik.errors.type}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  Create
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => navigate("/admin/payment/1")}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewPayment;
