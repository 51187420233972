import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";

function SocialMedia({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.userReducer);

  useEffect(() => {
    if (userid) {
      formik.setFieldValue(
        "whatsApp",
        userData.whatsApp ? userData.whatsApp : ""
      );
      formik.setFieldValue(
        "facebook",
        userData.facebook ? userData.facebook : ""
      );
      formik.setFieldValue(
        "instagram",
        userData.instagram ? userData.instagram : ""
      );
      formik.setFieldValue("x", userData.x ? userData.x : "");
      formik.setFieldValue(
        "linkedin",
        userData.linkedin ? userData.linkedin : ""
      );
      formik.setFieldValue("youtube", userData.youtube ? userData.youtube : "");
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      whatsApp: "",
      facebook: "",
      instagram: "",
      x: "",
      linkedin: "",
      youtube: "",
    },
    onSubmit: (values) => {
      const temp_obj = { ...values, navigate: navigate, id: userid };
      dispatch(update(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Social Media</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-6">
                <div className="form-group">
                  <label>WhatsApp</label>
                  <input
                    type="text"
                    className="form-control"
                    id="whatsApp"
                    name="whatsApp"
                    value={formik.values.whatsApp}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Facebook</label>
                  <input
                    type="text"
                    className="form-control"
                    name="facebook"
                    value={formik.values.facebook}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Instagram</label>
                  <input
                    type="text"
                    className="form-control"
                    name="instagram"
                    value={formik.values.instagram}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>X</label>
                  <input
                    type="text"
                    className="form-control"
                    name="x"
                    value={formik.values.x}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>Youtube</label>
                  <input
                    type="text"
                    className="form-control"
                    name="youtube"
                    value={formik.values.youtube}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>Linkedin</label>
                  <input
                    type="text"
                    className="form-control"
                    name="linkedin"
                    value={formik.values.linkedin}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default SocialMedia;
