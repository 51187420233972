import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";
import swal from "sweetalert";
import Paginate from "../../../components/pagination/paginate";
import { get, remove } from "../../../store/photoGallery/index";

function TableFilter() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { list, loading, isdeleted, paginate } = useSelector(
    (state) => state?.photoGalleryReducer
  );
  const [page, setPage] = useState(params.page);

  useEffect(() => {
    dispatch(get(page));
    navigate(`/admin/photo-gallery/${page}`);
  }, [page, isdeleted]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(remove(id));
      }
    });
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default dataTables_wrapper">
        <div className="card-body">
          {list?.length > 0 ? (
            <div className="table-responsive bootgrid scroll-delivery">
              <table className="table" id="bootgrid-command">
                <thead>
                  <tr>
                    <th>Image</th>
                    <th>STATUS</th>
                    <th
                      data-column-id="commands"
                      data-formatter="commands"
                      data-sortable="false"
                      className="text-center"
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                {list?.map((value, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>
                          {" "}
                          <img
                            src={value.image}
                            width={100}
                            className="img-fluid"
                          />
                        </td>
                        <td width={100}>
                          {value.active ? "Active" : "Inactive"}
                        </td>
                        <td style={{ textAlign: "right", width: 150 }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-info mr-2 command-edit"
                            data-row-id="10253"
                            onClick={() =>
                              navigate(
                                `/admin/photo-gallery/edit/${value?._id}`
                              )
                            }
                          >
                            <em className="fa fa-edit fa-fw"></em>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger command-delete"
                            data-row-id="10253"
                            onClick={() => handleDelete(value?._id)}
                          >
                            <em className="fa fa-trash fa-fw"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              {paginate && (
                <Paginate paginate={paginate} page={page} setPage={setPage} />
              )}
            </div>
          ) : (
            <div className="text-center">No records found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
