import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";


export const create = createAsyncThunk("feedback/create", async (params) => {
  try {
    const response = await httpService.post("/api/feedback", {}, params);
    if (response?.data) {
      toast.success("Feedback mails have sent successfully.");
    }
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const feedbackSlice = createSlice({
  name: "feedback",
  initialState: {
    loading: false,
    data: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(create.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(create.fulfilled), (state, action) => {
      state.data = action?.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(create.rejected), (state, action) => {
      state.loading = false;
    });
  },
});

export default feedbackSlice.reducer;
