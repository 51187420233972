import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const get = createAsyncThunk("payment/get", async (payload) => {
  try {
    let filter = [];
    if (payload && payload.user) filter.push(`user=${payload.user}`);
    if (payload && payload.search) filter.push(`search=${payload.search}`);
    filter.push(`page=${payload.page}`);
    const response = await httpService.get(`/api/payment?${filter.join("&")}`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const all = createAsyncThunk("payment/all", async () => {
  try {
    const response = await httpService.get(`/api/payment/all`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const create = createAsyncThunk("payment/create", async (params) => {
  try {
    const response = await httpService.post("/api/payment", {}, params);
    if (response?.data) {
      toast.success("Created successfully");
    }
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const update = createAsyncThunk("payment/update", async (params) => {
  try {
    const response = await httpService.put(
      `/api/payment/${params.id}`,
      {},
      params
    );
    if (response?.data) {
      toast.success("Updated successfully");
    }
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const getById = createAsyncThunk("payment/getById", async (id) => {
  try {
    const response = await httpService.get(`/api/payment/${id}`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const remove = createAsyncThunk("payment/delete", async (id) => {
  try {
    const response = await httpService.delete(`/api/payment/${id}`);
    if (response?.data) {
      response.data.id = id;
      toast.success("Deleted successfully");
    }
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const paymentSlice = createSlice({
  name: "Payment",
  initialState: {
    list: [],
    loading: false,
    paginate: "",
    data: "",
    isdeleted: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(get.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(get.fulfilled), (state, action) => {
      state.list = action.payload?.data?.docs;
      const paginate = {
        hasNextPage: action?.payload?.data?.hasNextPage,
        hasPrevPage: action?.payload?.data?.hasPrevPage,
        limit: action?.payload?.data?.limit,
        nextPage: action?.payload?.data?.nextPage,
        page: action?.payload?.data?.page,
        pagingCounter: action?.payload?.data?.pagingCounter,
        prevPage: action?.payload?.data?.prevPage,
        totalDocs: action?.payload?.data?.totalDocs,
        totalPages: action?.payload?.data?.totalPages,
      };
      state.paginate = paginate;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(get.rejected), (state, action) => {
      state.loading = false;
    });

    builder.addMatcher(isAllOf(all.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(all.fulfilled), (state, action) => {
      state.list = action.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(all.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(create.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(create.fulfilled), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(create.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(update.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(update.fulfilled), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(update.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(remove.pending), (state, action) => {
      state.loading = true;
      state.isdeleted = false;
    });
    builder.addMatcher(isAllOf(remove.fulfilled), (state, action) => {
      state.isdeleted = true;
      state.list?.splice(
        state.list?.findIndex((data) => data?._id === action.payload.id),
        1
      );
      state.loading = false;
    });
    builder.addMatcher(isAllOf(remove.rejected), (state) => {
      state.isdeleted = false;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(getById.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(getById.fulfilled), (state, action) => {
      state.data = action?.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(getById.rejected), (state) => {
      state.loading = false;
    });
  },
});

export default paymentSlice.reducer;
