import { useDispatch, useSelector } from "react-redux";
import { userCount } from "../../store/dashboard";
import { useEffect } from "react";

function Dashboard() {
  const dispatch = useDispatch();
  const { data } = useSelector((state) => state?.dashboardReducer);

  useEffect(() => {
    dispatch(userCount());
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div
          className="content-heading d-flex
                          justify-content-between"
        >
          <div>Dashboard</div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xl-4">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-4 d-flex align-items-center bg-primary-dark justify-content-center rounded-left">
                <em className="fas fa-users fa-3x"></em>
              </div>
              <div className="col-8 py-3 bg-primary rounded-right">
                <div className="h2 mt-0">{data?.user || 0}</div>
                <div className="text-uppercase">Total users </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-xl-4">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-4 d-flex align-items-center bg-purple-dark justify-content-center rounded-left">
                <em className="icon-user fa-3x"></em>
              </div>
              <div className="col-8 py-3 bg-purple rounded-right">
                <div className="h2 mt-0">{data?.male || 0}</div>
                <div className="text-uppercase">Total Males</div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 col-xl-4">
            <div className="card flex-row align-items-center align-items-stretch border-0">
              <div className="col-4 d-flex align-items-center bg-green-dark justify-content-center rounded-left">
                <em className="icon-user-female fa-3x"></em>
              </div>
              <div className="col-8 py-3 bg-green rounded-right">
                <div className="h2 mt-0">{data?.female || 0}</div>
                <div className="text-uppercase">Total Females</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Dashboard;
