import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import swal from "sweetalert";
import PageLoader from "../../components/PageLoader/PageLoader";
import Paginate from "../../components/pagination/paginate";
import { remove, get } from "../../store/user";
import moment from "moment";

function TableFilter() {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const { list: userList, loading, isdeleted, paginate } = useSelector(
    (state) => state.userReducer
  );
  const [page, setPage] = useState(params.page);

  useEffect(() => {
    dispatch(
      get({
        page: page,
      })
    );
    navigate(`/admin/users/${page}`);
  }, [page, isdeleted]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(remove(id));
      }
    });
  };

  const onSearch = (data) => {
    dispatch(
      get({
        page: page,
        search: data,
      })
    );
  };

  const planColor = (val) => {
    switch (val) {
      case "premium":
        return "badge-success";
      case "expiry":
        return "badge-danger";
      default:
        return "badge-secondary";
    }
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default dataTables_wrapper mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  placeholder="Search by candidateId, name, age, email, mobileNumber, gender, city"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-primary mr-3 mt-4"
                type="submit"
                style={{ fontSize: "17px" }}
                onClick={() => onSearch(search)}
              >
                Search
              </button>
              <button
                className="btn btn-secondary mt-4"
                type="submit"
                style={{ fontSize: "17px" }}
                onClick={() => {
                  setSearch("");
                  onSearch("");
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-default dataTables_wrapper">
        <div className="card-body">
          <div className="table-responsive bootgrid">
            <table className="table" id="bootgrid-command">
              <thead>
                <tr>
                  <th>CANDIDATE</th>
                  <th>PERSONAL INFORMATION</th>
                  <th>CONTACT DETAILS</th>
                  <th>MEMBER SINCE</th>
                  <th>PLAN STATUS</th>
                  <th>STATUS</th>
                  <th
                    data-column-id="commands"
                    data-formatter="commands"
                    data-sortable="false"
                    className="text-center"
                  >
                    ACTION
                  </th>
                </tr>
              </thead>
              {userList?.length > 0 &&
                userList?.map((value, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{value?.candidateId}</td>
                        <td width={400}>
                          <div className="row">
                            <div className="col-md-4">
                              <img
                                className="img-fluid"
                                src={value.images && value.images[0]}
                              />
                            </div>
                            <div className="col-md-8">
                              <h4>{value?.name}</h4>
                              <div>
                                <strong>Location: </strong>
                                {value?.state?.name}, {value?.city}
                              </div>
                              <div>
                                <strong>DOB: </strong>{" "}
                                {value?.dateOfBirth
                                  ? moment(value?.dateOfBirth).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </div>
                              <div>
                                <strong>Gender: </strong>
                                {value?.gender}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div>
                            <strong>Email: </strong>
                            {value?.email}
                          </div>
                          <div>
                            <strong>Phone: </strong>
                            {value.mobileNumber}
                          </div>
                        </td>
                        <td>
                          {moment(value?.created_at).format("DD-MM-YYYY")}
                        </td>
                        <td>
                          <span
                            className={`badge text-capitalize ${planColor(
                              value.planStatus
                            )}`}
                          >
                            {value.planStatus}
                          </span>
                        </td>
                        <td>{value.active ? "Active" : "Inactive"}</td>
                        <td style={{ textAlign: "right", width: 150 }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-info mr-2 command-edit"
                            data-row-id="10253"
                            onClick={() =>
                              navigate(
                                `/admin/users/basic-info/edit/${value?._id}`
                              )
                            }
                          >
                            <em className="fa fa-edit fa-fw"></em>
                          </button>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger command-delete"
                            data-row-id="10253"
                            onClick={() => handleDelete(value?._id)}
                          >
                            <em className="fa fa-trash fa-fw"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
            </table>
          </div>
          {paginate && (
            <Paginate paginate={paginate} page={page} setPage={setPage} />
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
