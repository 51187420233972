import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { create, getById, update } from "../../../store/successStorie/index";
import PageLoader from "../../../components/PageLoader/PageLoader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { fileUpload } from "../../../store/fileUpload/index";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function AddSuccessStorie() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data } = useSelector((state) => state?.successStorieReducer);
  const { loading: fileloading } = useSelector(
    (state) => state?.fileUploadReducer
  );
  const [allImages, setAllImages] = useState([]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        dispatch(fileUpload(file))
          .unwrap()
          .then((resp) => {
            if (resp.status) {
              setAllImages((imgs) => [...imgs, resp.data]);
            }
          })
          .catch((error) => {});
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] }, onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  useEffect(() => {
    if (id) {
      dispatch(getById(id));
    }
  }, [id]);

  useEffect(() => {
    if (data && id) {
      formik.setFieldValue("name", data?.name);
      formik.setFieldValue("about", data?.about);
      formik.setFieldValue("date", data?.date);
      formik.setFieldValue("active", data?.active);
      setAllImages(data.images ? data.images : []);
      // formik.setFieldValue("position", data?.position);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: "",
      about: "",
      date: "",
      active: true,
      // position: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      about: Yup.string().required("Required"),
      date: Yup.string().required("Required"),
      // position: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      console.log(values);
      let payload = {
        name: values.name,
        date: values.date,
        about: values.about,
        images: allImages,
        active: values.active,
        // position: values?.position,
        navigate: navigate,
      };
      if (id) {
        payload["id"] = id;
        dispatch(update(payload));
      } else {
        dispatch(create(payload));
      }
    },
  });

  const removeFileUpload = (indx) => {
    var array = [...allImages];
    array.splice(indx, 1);
    setAllImages(array);
  };

  return (
    <>
      {(loading || fileloading) && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/success-stories/1">Success Stories</Link>
            </span>
            <span> / {id ? data?.name : "New"}</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-4">
                  <div className="form-group">
                    <label>
                      Date <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="date"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.date}
                    />
                    {formik.touched.date && formik.errors.date ? (
                      <div style={{ color: "red" }}>{formik.errors.date}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>
                      Active <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"active"}
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue("active", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      About <span className="text-danger">*</span>
                    </label>
                    <textarea
                      className="form-control"
                      name="about"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.about}
                    ></textarea>
                    {formik.touched.about && formik.errors.about ? (
                      <div style={{ color: "red" }}>{formik.errors.about}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Images <span className="text-danger">*</span>
                    </label>
                    <div {...getRootProps({ style })}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files
                      </p>
                      {/* <em>
                    (5 files are the maximum number of files you can drop here)
                  </em> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-12 mt-3">
                  <div className="row">
                    {allImages.map((link, i) => (
                      <div className="col-md-3" key={i}>
                        <div className="image-area">
                          <img src={link} className="img-fluid mb-2" alt="" />
                          <div
                            className="remove-image"
                            onClick={() => removeFileUpload(i)}
                            style={{ display: "inline" }}
                          >
                            &#215;
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  {id ? "Update" : "Create"}
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    formik?.resetForm();
                    navigate(`/admin/success-stories/1`);
                  }}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddSuccessStorie;
