import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const appLoginUser = createAsyncThunk(
  "appUser/appLoginUser",
  async (params) => {
    try {
      const response = await httpService.post(
        "/api/auth/sign-in",
        {},
        {
          email: params.email,
          password: params.password,
          role: params.role,
        }
      );
      if (response.data) {
        response.data.navigate = params.navigate;
      }
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const appUserSlice = createSlice({
  name: "User",
  initialState: {
    user: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(appLoginUser.fulfilled), (state, action) => {
      state.user = action.payload?.data;
      if (action?.payload?.data?.accessToken) {
        localStorage.setItem("token", action?.payload?.data?.accessToken);
        localStorage.setItem("user", JSON.stringify(action?.payload?.data));
        action.payload.navigate("/dashboard");
      }
    });
  },
});

export default appUserSlice.reducer;
