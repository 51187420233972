import Breadcrumb from "react-bootstrap/Breadcrumb";
import { useParams } from "react-router-dom";

function Breadcb({ user }) {
  const { type } = useParams();
  return (
    <>
      <Breadcrumb className="p-0">
        <Breadcrumb.Item href="/admin/users/1">Candidate</Breadcrumb.Item>
        <Breadcrumb.Item href={`/admin/users/basic-info/edit/${user?._id}`}>
          {user?.email}
        </Breadcrumb.Item>
      </Breadcrumb>
    </>
  );
}

export default Breadcb;
