import { useState } from "react";
import DatePicker from "react-datepicker";

export default function CustomDate({
  selected,
  onChange,
  disabled = false,
  name,
}) {
  const getFullYear = new Date().getFullYear();

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const range = (start, end) =>
    Array.from(Array(Math.abs(end - start) + 1), (_, i) => start + i);

  const years = range(1950, getFullYear);

  return (
    <DatePicker
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <select
            className="mr-2 form-control w-50"
            value={new Date(date).getFullYear()}
            onChange={({ target: { value } }) => changeYear(value)}
          >
            {years.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>

          <select
            className="form-control w-50"
            value={months[new Date(date).getMonth()]}
            onChange={({ target: { value } }) =>
              changeMonth(months.indexOf(value))
            }
          >
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      name={name}
      selected={selected}
      onChange={(date) => onChange(date)}
      className="form-control "
      maxDate={new Date()}
      dateFormat="dd/MM/yyyy"
      placeholderText="DD/MM/YYYY"
      disabled={disabled}
    />
  );
}
