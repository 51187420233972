import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import PageLoader from "../../components/PageLoader/PageLoader";
import { create } from "../../store/user";
import * as Yup from "yup";

function NewUser() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.userReducer);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      mobileNumber: "",
      password: "",
      role: "user",
      active: true,
      verify: false,
      profileHighlighter: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Full name is a required")
        .min(6),
      email: Yup.string()
        .required("Email is a required")
        .email("Email must be a valid email"),
      mobileNumber: Yup.string().required("Phone is a required"),
      password: Yup.string()
        .required("Password is a required")
        .min(8, "Password must be at least 8 characters")
        .max(30, "Password must be at most 30 characters"),
    }),
    onSubmit: (values) => {
      const temp_obj = {
        name: values.name,
        email: values.email,
        mobileNumber: values.mobileNumber,
        password: values.password,
        role: values.role,
        active: values.active,
        verify: values.verify,
        profileHighlighter: values.profileHighlighter,
        navigate: navigate,
      };
      dispatch(create(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/users/1">Candidate </Link>
            </span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row" style={{ padding: "0 0 15px 0" }}>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Full Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="email"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div style={{ color: "red" }}>{formik.errors.email}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Phone <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="mobileNumber"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobileNumber}
                    />
                    {formik.touched.mobileNumber &&
                    formik.errors.mobileNumber ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.mobileNumber}
                      </div>
                    ) : null}
                  </div>
                  {/* <div className="form-group">
                    <label>Roles</label>
                    <select
                      className="form-control"
                      name="role"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.role}
                    >
                      <option value={"user"}>User</option>
                      <option value={"admin"}>Admin</option>
                    </select>
                  </div> */}
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Password <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="password"
                      type="password"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik?.values?.password}
                    />
                    {formik?.touched?.password && formik?.errors?.password ? (
                      <div style={{ color: "red" }}>
                        {formik?.errors?.password}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="form-group">
                    <label>
                      Active <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"active"}
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue("active", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Active
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-group">
                    <label>
                      Verify Profile <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"verify"}
                          checked={formik.values.verify}
                          onChange={(e) => {
                            formik.setFieldValue("verify", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Verify Profile
                      </label>
                    </div>
                  </div>
                </div>

                <div className="col-md-2">
                  <div className="form-group">
                    <label>
                      Profile Highlighter <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"profileHighlighter"}
                          checked={formik.values.profileHighlighter}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "profileHighlighter",
                              e.target.checked
                            );
                          }}
                        />
                        <span className="fa fa-check"></span>Is Profile
                        Highlighter
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  Create
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => navigate("/admin/users/1")}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewUser;
