import { Link, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Tab, Col, Nav, Row } from "react-bootstrap";
import Breadcb from "../breadcrumb/breadcrumb";
import BasicInformation from "../../pages/user/editUser/basic";
import AdvancedBio from "../../pages/user/editUser/advanced";
import SocioReligious from "../../pages/user/editUser/socioReligious";
import Education from "../../pages/user/editUser/education";
import SocialMedia from "../../pages/user/editUser/socialMedia";
import Hobbies from "../../pages/user/editUser/hobbies";
import Images from "../../pages/user/editUser/images";
import Payment from "../../pages/user/editUser/payment";

export default function UserTabs({ userData }) {
  const { userid } = useParams();
  const { type } = useParams();
  const { action } = useParams();
  const navigate = useNavigate();
  const [tab, setTab] = useState("basic-info");

  useEffect(() => {
    if (type) {
      setTab(type);
    }
  }, [type]);

  return (
    <div>
      <Tab.Container
        activeKey={tab}
        id="left-tabs-example"
        defaultActiveKey="basic-info"
      >
        <Row sm={12}>
          <div className="content-wrapper">
            <div
              className="content-heading d-flex
                          justify-content-between"
              style={{ marginBottom: "0px" }}
            >
              <Breadcb user={userData} />
            </div>
          </div>
        </Row>
        <Row className="card-margin">
          <Col sm={9}>
            <Tab.Content style={{ borderStyle: "unset", padding: "unset" }}>
              <Tab.Pane eventKey="basic-info">
                <BasicInformation userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="advanced-bio">
                <AdvancedBio userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="socio-religious">
                <SocioReligious userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="education">
                <Education userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="social-media">
                <SocialMedia userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="hobbies">
                <Hobbies userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="images">
                <Images userData={userData} />
              </Tab.Pane>
              <Tab.Pane eventKey="payment">
                <Payment userData={userData} />
              </Tab.Pane>
            </Tab.Content>
          </Col>
          <Col sm={3}>
            <div className="card card-default">
              <div className="card-body">
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link
                      eventKey="basic-info"
                      as={Link}
                      to={`/admin/users/basic-info/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 far fa-edit"></em>
                        </span>
                        <span>Basic Info</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="advanced-bio"
                      as={Link}
                      to={`/admin/users/advanced-bio/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 far fa-user"></em>
                          {/* <em className="mr-2 far fa-images"></em> */}
                        </span>
                        <span>Advanced Bio</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="socio-religious"
                      as={Link}
                      to={`/admin/users/socio-religious/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 far fa-list-alt"></em>
                          {/* <em className="mr-2 far fa-images"></em> */}
                        </span>
                        <span>Socio Religious</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="education"
                      as={Link}
                      to={`/admin/users/education/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 fa fa-graduation-cap "></em>
                          {/* <em className="mr-2 far fa-images"></em> */}
                        </span>
                        <span>Job & Education</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="social-media"
                      as={Link}
                      to={`/admin/users/social-media/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 fa fa-life-ring"></em>
                          {/* <em className="mr-2 far fa-images"></em> */}
                        </span>
                        <span>Social Media</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="hobbies"
                      as={Link}
                      to={`/admin/users/hobbies/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 fa fa-star"></em>
                          {/* <em className="mr-2 far fa-images"></em> */}
                        </span>
                        <span>Hobbies</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="images"
                      as={Link}
                      to={`/admin/users/images/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 far fa-images"></em>
                        </span>
                        <span>Images</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link
                      eventKey="payment"
                      as={Link}
                      to={`/admin/users/payment/edit/${userid}`}
                    >
                      <div>
                        <span>
                          <em className="mr-2 fa fa-credit-card"></em>
                        </span>
                        <span>Payment</span>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>
            </div>
          </Col>
        </Row>
      </Tab.Container>
    </div>
  );
}
