import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import PageLoader from "../../components/PageLoader/PageLoader";
import { createReview } from "../../store/review";
import Select from "react-select";
import * as Yup from "yup";
import { get } from "../../store/user";

function NewReview() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, list: userList } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(get(1));
  }, []);

  const formik = useFormik({
    initialValues: {
      user: "",
      text: "",
      active: true,
    },
    validationSchema: Yup.object({
      user: Yup.string().required("Required"),
      text: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
      const temp_obj = {
        ...values,
        navigate: navigate,
      };
      dispatch(createReview(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/review/1">Review</Link>
            </span>
            <span>/ New</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row" style={{ padding: "0 0 15px 0" }}>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <Select
                      name="user"
                      options={userList || []}
                      onChange={(data) => {
                        formik.setFieldValue("user", data?._id);
                      }}
                      className="basic-multi-select"
                      classNamePrefix="select"
                      placeholder="Select.."
                      getOptionLabel={(option) => option.name}
                      getOptionValue={(option) => option._id}
                    />
                    {formik.touched.user && formik.errors.user ? (
                      <div style={{ color: "red" }}>{formik.errors.user}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Active <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"active"}
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue("active", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Active
                      </label>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Text <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="text"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.text}
                    />
                    {formik.touched.text && formik.errors.text ? (
                      <div style={{ color: "red" }}>{formik.errors.text}</div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  Create
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => navigate("/admin/review/1")}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default NewReview;
