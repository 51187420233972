import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import UserTabs from "../../../components/UserTabs";
import { getById } from "../../../store/user";

function EditUser() {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const { data: userData } = useSelector((state) => state?.userReducer);

  useEffect(() => {
    if (userid) {
      dispatch(getById(userid));
    }
  }, [userid]);

  return (
    <>
      <UserTabs userData={userData} />
    </>
  );
}
export default EditUser;
