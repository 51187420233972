import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";

import axios from "axios";

export const fileUpload = createAsyncThunk(
  "files/fileUpload",
  async (payload) => {
    try {
      const formData = new FormData();
      formData.append("files", payload);
      if (payload.uploadtype) formData.append("uploadtype", payload.uploadtype);
      const http = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const response = await http.post("/api/uploadfile", formData);
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const fileUploadSlice = createSlice({
  name: "FileUpload",
  initialState: {
    loading: false,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(fileUpload.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(fileUpload.fulfilled), (state, action) => {
      state.data = action?.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(fileUpload.rejected), (state) => {
      state.loading = false;
    });
  },
});

export default fileUploadSlice.reducer;
