import { Fragment, useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "./App.css";
import Login from "./pages/login";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ProtectedRoute from "./routes/protectedRoute";
import PrivateRoute from "./routes/privateRoute";
import Dashboard from "./pages/dashboard";
import SignUp from "./pages/signUp";
import User from "./pages/user/index";
import NewUser from "./pages/user/add";
import EditUser from "./pages/user/editUser";
import { IsTokenExpired } from "./common/jwtToken";
import Review from "./pages/review";
import NewReview from "./pages/review/add";
import State from "./pages/Configurations/state";
import AddState from "./pages/Configurations/state/add";
import ProfileCreated from "./pages/Configurations/profileCreated";
import AddProfileCreated from "./pages/Configurations/profileCreated/add";
import SpecialCase from "./pages/Configurations/specialCase";
import AddSpecialCase from "./pages/Configurations/specialCase/add";
import Religion from "./pages/Configurations/religion";
import AddReligion from "./pages/Configurations/religion/add";
import Caste from "./pages/Configurations/caste";
import AddCaste from "./pages/Configurations/caste/add";
import Language from "./pages/Configurations/languages";
import AddLanguage from "./pages/Configurations/languages/add";
import Qualification from "./pages/Configurations/qualification";
import AddQualification from "./pages/Configurations/qualification/add";
import Occupation from "./pages/Configurations/occupation";
import AddOccupation from "./pages/Configurations/occupation/add";
import SuccessStorie from "./pages/Configurations/successStories";
import AddSuccessStorie from "./pages/Configurations/successStories/add";
import AddPhotoGallery from "./pages/Configurations/photoGallery/add";
import PhotoGallery from "./pages/Configurations/photoGallery";
import ScheduleMeeting from "./pages/Configurations/scheduleMeeting";
import Payment from "./pages/Configurations/payment";
import NewPayment from "./pages/Configurations/payment/add";
import Feedback from "./pages/Configurations/feedback";

function App() {
  const navigate = useNavigate();
  // const socket = useContext(SocketContext);

  const token = localStorage.getItem("token");

  // console.log(socket);
  useEffect(() => {
    if (token !== null) {
      const isExpired = IsTokenExpired(token);
      if (isExpired === true) {
        localStorage.removeItem("token");
        navigate("/");
      }
    }

    // socket.on("orderplace", (data) => {
    //   toast.success(data);
    // });
  }, [token]);

  return (
    <Fragment>
      <Routes>
        <Route element={<ProtectedRoute />}>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/admin/users" element={<User />} />
          <Route path="/admin/users/:page" element={<User />} />
          <Route path="/admin/users/new" element={<NewUser />} />
          <Route path="/admin/users" element={<EditUser />}>
            <Route path="/admin/users/:type" />
            <Route path="/admin/users/:type/:action" />
            <Route path="/admin/users/:type/:action/:userid" />
          </Route>
          <Route path="/admin/schedule-meeting" element={<ScheduleMeeting />} />
          <Route
            path="/admin/schedule-meeting/:page"
            element={<ScheduleMeeting />}
          />
          <Route path="/admin/review" element={<Review />} />
          <Route path="/admin/review/:page" element={<Review />} />
          <Route path="/admin/review/new" element={<NewReview />} />

          <Route path="/admin/payment" element={<Payment />} />
          <Route path="/admin/payment/:page" element={<Payment />} />
          <Route path="/admin/payment/new" element={<NewPayment />} />

          <Route path="/admin/profile-created" element={<ProfileCreated />} />
          <Route
            path="/admin/profile-created/:page"
            element={<ProfileCreated />}
          />
          <Route
            path="/admin/profile-created/new"
            element={<AddProfileCreated />}
          />
          <Route
            path="/admin/profile-created/edit/:id"
            element={<AddProfileCreated />}
          />
          <Route path="/admin/special-case" element={<SpecialCase />} />
          <Route path="/admin/special-case/:page" element={<SpecialCase />} />
          <Route path="/admin/special-case/new" element={<AddSpecialCase />} />
          <Route
            path="/admin/special-case/edit/:id"
            element={<AddSpecialCase />}
          />

          <Route path="/admin/state" element={<State />} />
          <Route path="/admin/state/:page" element={<State />} />
          <Route path="/admin/state/new" element={<AddState />} />
          <Route path="/admin/state/edit/:id" element={<AddState />} />

          <Route path="/admin/religions" element={<Religion />} />
          <Route path="/admin/religions/:page" element={<Religion />} />
          <Route path="/admin/religions/new" element={<AddReligion />} />
          <Route path="/admin/religions/edit/:id" element={<AddReligion />} />

          <Route path="/admin/castes" element={<Caste />} />
          <Route path="/admin/castes/:page" element={<Caste />} />
          <Route path="/admin/castes/new" element={<AddCaste />} />
          <Route path="/admin/castes/edit/:id" element={<AddCaste />} />

          <Route path="/admin/languages" element={<Language />} />
          <Route path="/admin/languages/:page" element={<Language />} />
          <Route path="/admin/languages/new" element={<AddLanguage />} />
          <Route path="/admin/languages/edit/:id" element={<AddLanguage />} />

          <Route path="/admin/qualification" element={<Qualification />} />
          <Route
            path="/admin/qualification/:page"
            element={<Qualification />}
          />
          <Route
            path="/admin/qualification/new"
            element={<AddQualification />}
          />
          <Route
            path="/admin/qualification/edit/:id"
            element={<AddQualification />}
          />

          <Route path="/admin/occupation" element={<Occupation />} />
          <Route path="/admin/occupation/:page" element={<Occupation />} />
          <Route path="/admin/occupation/new" element={<AddOccupation />} />
          <Route
            path="/admin/occupation/edit/:id"
            element={<AddOccupation />}
          />

          <Route path="/admin/success-stories" element={<SuccessStorie />} />
          <Route
            path="/admin/success-stories/:page"
            element={<SuccessStorie />}
          />
          <Route
            path="/admin/success-stories/new"
            element={<AddSuccessStorie />}
          />
          <Route
            path="/admin/success-stories/edit/:id"
            element={<AddSuccessStorie />}
          />

          <Route path="/admin/photo-gallery" element={<PhotoGallery />} />
          <Route path="/admin/photo-gallery/:page" element={<PhotoGallery />} />
          <Route
            path="/admin/photo-gallery/new"
            element={<AddPhotoGallery />}
          />
          <Route
            path="/admin/photo-gallery/edit/:id"
            element={<AddPhotoGallery />}
          />
 <Route
            path="/admin/feedback"
            element={<Feedback />}
          />

        </Route>
        <Route element={<PrivateRoute />}>
          <Route path="/" element={<Login />} />
        </Route>
        <Route path="/signup" element={<SignUp />} />
      </Routes>
      <ToastContainer />
    </Fragment>
  );
}

export default App;
