import { useNavigate } from "react-router-dom";
import TableFilter from "./tableFilter";

function ProfileCreated() {
  const navigate = useNavigate();
  return (
    <>
      <div className="content-wrapper">
        <div
          className="content-heading d-flex
                          justify-content-between"
        >
          <div>Profile For</div>
          <div>
            <button
              className="btn btn-labeled btn-success"
              type="button"
              style={{ fontSize: "17px" }}
              onClick={() => navigate("/admin/profile-created/new")}
            >
              <span className="btn-label">
                <em className="fas fa-plus"></em>
              </span>
              New Profile For
            </button>
          </div>
        </div>
        <TableFilter />
      </div>
    </>
  );
}

export default ProfileCreated;
