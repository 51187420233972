import AddFeedback from "./add";

function Feedback() {
  return (
    <>
      <div className="content-wrapper">
        <div
          className="content-heading d-flex
                          justify-content-between"
        >
          <div>Feedback</div>
        </div>
      <AddFeedback />
      </div>
    </>
  );
}

export default Feedback;
