import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { create, getById, update } from "../../../store/profileCreated/index";
import PageLoader from "../../../components/PageLoader/PageLoader";

function AddProfileCreated() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data } = useSelector(
    (state) => state?.profileCreatedReducer
  );

  useEffect(() => {
    if (id) {
      dispatch(getById(id));
    }
  }, [id]);

  useEffect(() => {
    if (data && id) {
      formik.setFieldValue("name", data?.name);
      formik.setFieldValue("active", data?.active);
      formik.setFieldValue("isGanderSelection", data?.isGanderSelection);
      formik.setFieldValue("gander", data?.gander);
      // formik.setFieldValue("position", data?.position);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: "",
      active: true,
      isGanderSelection: true,
      gander: "",
      // position: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      // position: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      let payload = {
        name: values.name,
        active: values.active,
        isGanderSelection: values.isGanderSelection,
        gander: values.gander,
        // position: values?.position,
        navigate: navigate,
      };
      if (id) {
        payload["id"] = id;
        dispatch(update(payload));
      } else {
        dispatch(create(payload));
      }
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/profile-created/1">Profile For</Link>
            </span>
            <span> / {id ? data?.name : "New"}</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Active <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"active"}
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue("active", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Active
                      </label>
                    </div>
                  </div>
                </div>

                {/* <div className="col-md-4">
                  <div className="form-group">
                    <label>Position *</label>
                    <input
                      className="form-control"
                      name="position"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.position}
                    />
                    {formik.touched.position && formik.errors.position ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.position}
                      </div>
                    ) : null}
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Gander Selection <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"isGanderSelection"}
                          checked={formik.values.isGanderSelection}
                          onChange={(e) => {
                            formik.setFieldValue(
                              "isGanderSelection",
                              e.target.checked
                            );
                          }}
                        />
                        <span className="fa fa-check"></span> Is Gander
                        Selection
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {formik.values.isGanderSelection === false && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label>Gander Type</label>

                      <select
                        className="form-control"
                        name="gander"
                        value={formik.values.gander}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value={""}>Select your gender type</option>
                        <option value={"male"}>Male</option>
                        <option value={"female"}>Female</option>
                      </select>
                    </div>
                  </div>
                </div>
              )}

              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  {id ? "Update" : "Create"}
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    formik?.resetForm();
                    navigate(`/admin/profile-created/1`);
                  }}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddProfileCreated;
