const Menu = [
  {
    heading: "Main Navigation",
    translate: "sidebar.heading.HEADER",
  },
  {
    name: "Dashboard",
    translate: "sidebar.nav.MENU",
    path: "/dashboard",
    icon: "icon-grid",
  },
  {
    name: "Candidate",
    translate: "sidebar.nav.MENU",
    path: "/admin/users/1",
    icon: "icon-grid",
  },
  {
    name: "Schedule Meeting",
    translate: "sidebar.nav.MENU",
    path: "/admin/schedule-meeting/1",
    icon: "icon-grid",
  },
  {
    name: "Payment",
    translate: "sidebar.nav.MENU",
    path: "/admin/payment/1",
    icon: "icon-grid",
  },
  {
    name: "Religion",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/religions/1",
    icon: "icon-grid",
  },
  {
    name: "Language",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/languages/1",
    icon: "icon-grid",
  },
  {
    name: "Caste",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/castes/1",
    icon: "icon-grid",
  },
  {
    name: "Profile For",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/profile-created/1",
    icon: "icon-grid",
  },
  {
    name: "Special Case",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/special-case/1",
    icon: "icon-grid",
  },
  {
    name: "Education",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/qualification/1",
    icon: "icon-grid",
  },
  {
    name: "State",
    translate: "sidebar.nav.MENU",
    path: "/admin/state/1",
    icon: "icon-grid",
  },
  {
    name: "Occupation",
    translate: "sidebar.nav.SUBMENU",
    path: "/admin/occupation/1",
    icon: "icon-grid",
  },
  {
    name: "Review",
    translate: "sidebar.nav.MENU",
    path: "/admin/review/1",
    icon: "icon-grid",
  },
  {
    name: "Success Stories",
    translate: "sidebar.nav.MENU",
    path: "/admin/success-stories/1",
    icon: "icon-grid",
  },
  {
    name: "Photo Gallery",
    translate: "sidebar.nav.MENU",
    path: "/admin/photo-gallery/1",
    icon: "icon-grid",
  },
  {
    name: "Feedback",
    translate: "sidebar.nav.MENU",
    path: "/admin/feedback",
    icon: "icon-grid",
  },
];

export default Menu;
