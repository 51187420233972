import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect } from "react";
import { create, getById, update } from "../../../store/specialCase/index";
import PageLoader from "../../../components/PageLoader/PageLoader";

function AddSpecialCase() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, data } = useSelector((state) => state?.specialCaseReducer);

  useEffect(() => {
    if (id) {
      dispatch(getById(id));
    }
  }, [id]);

  useEffect(() => {
    if (data && id) {
      formik.setFieldValue("name", data?.name);
      formik.setFieldValue("active", data?.active);
      formik.setFieldValue("position", data?.position);
    }
  }, [data]);

  const formik = useFormik({
    initialValues: {
      name: "",
      active: true,
      position: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Required"),
      position: Yup.number().required("Required"),
    }),
    onSubmit: (values) => {
      let payload = {
        name: values.name,
        active: values.active,
        position: values?.position,
        navigate: navigate,
      };
      if (id) {
        payload["id"] = id;
        dispatch(update(payload));
      } else {
        dispatch(create(payload));
      }
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="content-wrapper">
        <div className="content-heading">
          <div>
            <span>
              <Link to="/admin/special-case/1">Special Case</Link>
            </span>
            <span> / {id ? data?.name : "New"}</span>
          </div>
        </div>
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      Name <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="name"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div style={{ color: "red" }}>{formik.errors.name}</div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>Position *</label>
                    <input
                      className="form-control"
                      name="position"
                      type="number"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.position}
                    />
                    {formik.touched.position && formik.errors.position ? (
                      <div style={{ color: "red" }}>
                        {formik.errors.position}
                      </div>
                    ) : null}
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="form-group">
                    <label>
                      Active <span className="text-danger">*</span>
                    </label>
                    <div className="checkbox c-checkbox">
                      <label className="mb-1">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          name={"active"}
                          checked={formik.values.active}
                          onChange={(e) => {
                            formik.setFieldValue("active", e.target.checked);
                          }}
                        />
                        <span className="fa fa-check"></span>Is Active
                      </label>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  {id ? "Update" : "Create"}
                </button>
                <span style={{ padding: "0 10px" }}>or</span>
                <button
                  className="btn btn-labeled btn-secondary mb-2"
                  type="button"
                  style={{ fontSize: "17px" }}
                  onClick={() => {
                    formik?.resetForm();
                    navigate(`/admin/special-case/1`);
                  }}
                >
                  <span className="btn-label">
                    <i className="fa fa-times"></i>
                  </span>
                  Cancel
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddSpecialCase;
