import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const userCount = createAsyncThunk("dashboard/userCount", async () => {
  try {
    const response = await httpService.get(`/api/user/total/count`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const dashboardSlice = createSlice({
  name: "Dashboard",
  initialState: {
    loading: false,
    data: {},
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(userCount.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(userCount.fulfilled), (state, action) => {
      state.data = action.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(userCount.rejected), (state, action) => {
      state.loading = false;
    });
  },
});

export default dashboardSlice.reducer;
