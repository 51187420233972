import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDropzone } from "react-dropzone";
import { fileUpload } from "../../../store/fileUpload";

const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "20px",
  borderWidth: 2,
  borderRadius: 2,
  borderColor: "#eeeeee",
  borderStyle: "dashed",
  backgroundColor: "#fafafa",
  color: "#bdbdbd",
  outline: "none",
  transition: "border .24s ease-in-out",
};

const focusedStyle = {
  borderColor: "#2196f3",
};

const acceptStyle = {
  borderColor: "#00e676",
};

const rejectStyle = {
  borderColor: "#ff1744",
};

function Images({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [allImages, setAllImages] = useState([]);
  const { loading } = useSelector((state) => state?.userReducer);
  const { loading: fileloading } = useSelector(
    (state) => state?.fileUploadReducer
  );

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = async () => {
        var payload = file;
        payload["uploadtype"] = "user";
        dispatch(fileUpload(payload))
          .unwrap()
          .then((resp) => {
            if (resp.status) {
              setAllImages((imgs) => [...imgs, resp.data]);
            }
          })
          .catch((error) => {});
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ accept: { "image/*": [] }, onDrop });

  useEffect(() => {
    if (userid) {
      setAllImages(userData.images ? userData.images : []);
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      images: "",
    },
    onSubmit: (values) => {
      const temp_obj = { images: allImages, navigate: navigate, id: userid };
      dispatch(update(temp_obj));
    },
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject]
  );

  const removeFileUpload = (indx) => {
    var array = [...allImages];
    array.splice(indx, 1);
    setAllImages(array);
  };

  return (
    <>
      {(loading || fileloading) && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">
            Images <span className="text-danger">*</span>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-12">
                <div {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <p>Drag 'n' drop some files here, or click to select files</p>
                  {/* <em>
                    (5 files are the maximum number of files you can drop here)
                  </em> */}
                </div>
              </div>
              <div className="col-md-12 mt-3">
                <div className="row">
                  {allImages.map((link, i) => (
                    <div className="col-md-3" key={i}>
                      <div className="image-area">
                        <img src={link} className="img-fluid mb-2" alt="" />
                        <div
                          className="remove-image"
                          onClick={() => removeFileUpload(i)}
                          style={{ display: "inline" }}
                        >
                          &#215;
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Images;
