import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import PageLoader from "../../components/PageLoader/PageLoader";
import Paginate from "../../components/pagination/paginate";
import { deleteReview, getReview } from "../../store/review";

function TableFilter() {
  const dispatch = useDispatch();
  const params = useParams();
  const { reviews, paginate, loading } = useSelector(
    (state) => state?.reviewReducer
  );
  const [page, setPage] = useState(params.page);

  useEffect(() => {
    dispatch(getReview(page));
  }, [page]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(deleteReview(id));
      }
    });
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default dataTables_wrapper">
        <div className="card-body">
          {reviews?.length > 0 ? (
            <div className="table-responsive bootgrid scroll-delivery">
              <table className="table" id="bootgrid-command">
                <thead>
                  <tr>
                    <th>NAME</th>
                    <th>TEXT</th>
                    <th>STATUS</th>
                    <th
                      data-column-id="commands"
                      data-formatter="commands"
                      data-sortable="false"
                      className="text-center"
                    >
                      ACTION
                    </th>
                  </tr>
                </thead>
                {reviews?.map((value, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>{value?.user?.name}</td>
                        <td>{value.text}</td>
                        <td width={70}>
                          {value.active ? "Active" : "Inactive"}
                        </td>
                        <td style={{ textAlign: "right", width: 120 }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger command-delete"
                            data-row-id="10253"
                            onClick={() => handleDelete(value?._id)}
                          >
                            <em className="fa fa-trash fa-fw"></em>
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              {paginate && (
                <Paginate paginate={paginate} page={page} setPage={setPage} />
              )}
            </div>
          ) : (
            <div className="text-center">No records found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
