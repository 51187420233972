import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import PageLoader from "../../../components/PageLoader/PageLoader";
import swal from "sweetalert";
import Paginate from "../../../components/pagination/paginate";
import { editscheduleMeeting, get } from "../../../store/scheduleMeeting/index";
import moment from "moment";

function TableFilter() {
  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();
  const { list, loading, paginate } = useSelector(
    (state) => state?.scheduleMeetingReducer
  );
  const [page, setPage] = useState(params.page);

  useEffect(() => {
    dispatch(get(page));
  }, [page]);

  const scheduleMeeting = (id, val) => {
    const payload = {
      id: id,
      meetingStatus: val,
    };
    dispatch(editscheduleMeeting(payload))
      .unwrap()
      .then((data) => {
        if (data?.status) {
          dispatch(get(page));
        }
      });
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default dataTables_wrapper">
        <div className="card-body">
          {list?.length > 0 ? (
            <div className="table-responsive bootgrid scroll-delivery">
              <table className="table" id="bootgrid-command">
                <thead>
                  <tr>
                    <th>Schedule By</th>
                    <th>Schedule With</th>
                    <th className="text-center">STATUS</th>
                  </tr>
                </thead>
                {list?.map((value, index) => {
                  const item =
                    value?.scheduleBy?._id === value?.sender?._id
                      ? value?.receiver
                      : value?.sender;
                  return (
                    <tbody key={index}>
                      <tr>
                        <td width={400}>
                          <div className="row">
                            <div className="col-md-4">
                              <img
                                className="img-fluid"
                                src={
                                  value?.scheduleBy?.images &&
                                  value?.scheduleBy?.images[0]
                                }
                              />
                            </div>
                            <div className="col-md-8">
                              <h4>{value?.scheduleBy?.name}</h4>
                              <div>
                                <strong>Email: </strong>
                                {value?.scheduleBy?.email}
                              </div>
                              <div>
                                <strong>Mobile: </strong>
                                {value?.scheduleBy?.mobileNumber}
                              </div>
                              <div>
                                <strong>DOB: </strong>{" "}
                                {value?.scheduleBy?.dateOfBirth
                                  ? moment(
                                      value?.scheduleBy?.dateOfBirth
                                    ).format("DD-MM-YYYY")
                                  : "-"}
                              </div>
                              <div>
                                <strong>Gender: </strong>
                                {value?.scheduleBy?.gender }
                              </div>
                            </div>
                          </div>
                        </td>
                        <td width={400}>
                          <div className="row">
                            <div className="col-md-4">
                              <img
                                className="img-fluid"
                                src={item?.images && item?.images[0]}
                              />
                            </div>
                            <div className="col-md-8">
                              <h4>{item?.name}</h4>
                              <div>
                                <strong>Email: </strong>
                                {item?.email}
                              </div>
                              <div>
                                <strong>Mobile: </strong>
                                {item?.mobileNumber}
                              </div>
                              <div>
                                <strong>DOB: </strong>{" "}
                                {item?.dateOfBirth
                                  ? moment(item?.dateOfBirth).format(
                                      "DD-MM-YYYY"
                                    )
                                  : "-"}
                              </div>
                              <div>
                                <strong>Gender: </strong>
                                {item?.gender}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td width={120}>
                          <select
                            className="form-control"
                            value={value.meetingStatus}
                            onChange={(e) =>
                              scheduleMeeting(value._id, e?.target?.value)
                            }
                          >
                            <option value="pending">Pending</option>
                            <option value="done">Done</option>
                            <option value="cancelled">Cancelled</option>
                          </select>
                        </td>
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              {paginate && (
                <Paginate paginate={paginate} page={page} setPage={setPage} />
              )}
            </div>
          ) : (
            <div className="text-center">No records found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
