import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";
import * as Yup from "yup";
import ReactDatePicker from "react-datepicker";
import { all as allState } from "../../../store/state";
import { all as allProfileCreated } from "../../../store/profileCreated";
import { all as allSpecialCase } from "../../../store/specialCase";
import CustomDate from "../../../components/customDate";

const bodyTypeList = [
  {
    id: "Slim",
    name: "Slim",
  },
  {
    id: "Average",
    name: "Average",
  },
  {
    id: "Athletic",
    name: "Athletic",
  },
  {
    id: "Heavy",
    name: "Heavy",
  },
];

const complexionList = [
  {
    id: "Fair",
    name: "Fair",
  },
  {
    id: "Very Fair",
    name: "Very Fair",
  },
  {
    id: "Wheatish",
    name: "Wheatish",
  },
  {
    id: "Wheatish Medium",
    name: "Wheatish Medium",
  },
  {
    id: "Dark",
    name: "Dark",
  },
];

const eatingHabitList = [
  {
    id: "Jain",
    name: "Jain",
  },
  {
    id: "Vegetarian",
    name: "Vegetarian",
  },
  {
    id: "Vegetarian With Eggs",
    name: "Vegetarian With Eggs",
  },
  {
    id: "Non Vegetarian",
    name: "Non Vegetarian",
  },
];

function AdvancedBio({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state) => state?.userReducer);
  const { list: state } = useSelector((state) => state?.stateReducer);
  const { list: profilecreated } = useSelector(
    (state) => state?.profileCreatedReducer
  );
  const { list: specialcase } = useSelector(
    (state) => state?.specialCaseReducer
  );

  useEffect(() => {
    dispatch(allState());
    dispatch(allProfileCreated());
    dispatch(allSpecialCase());
  }, []);

  useEffect(() => {
    if (userid) {
      formik.setFieldValue(
        "profileCreatedFor",
        userData?.profileCreatedFor?._id ? userData?.profileCreatedFor?._id : ""
      );
      formik.setFieldValue("gender", userData.gender ? userData.gender : "");
      formik.setFieldValue(
        "dateOfBirth",
        userData.dateOfBirth ? userData.dateOfBirth : ""
      );
      formik.setFieldValue(
        "height.feet",
        userData?.height?.feet ? userData.height.feet : ""
      );
      formik.setFieldValue(
        "height.inches",
        userData?.height?.inches ? userData.height.inches : ""
      );
      formik.setFieldValue("weight", userData.weight ? userData.weight : "");
      formik.setFieldValue(
        "bodyType",
        userData.bodyType ? userData.bodyType : ""
      );
      formik.setFieldValue(
        "complexion",
        userData.complexion ? userData.complexion : ""
      );
      formik.setFieldValue("smoking", userData.smoking ? userData.smoking : "");
      formik.setFieldValue(
        "drinking",
        userData.drinking ? userData.drinking : ""
      );
      formik.setFieldValue(
        "eatingHabits",
        userData.eatingHabits ? userData.eatingHabits : ""
      );
      formik.setFieldValue(
        "specialCase",
        userData?.specialCase?._id ? userData?.specialCase?._id : ""
      );
      formik.setFieldValue(
        "state",
        userData?.state?._id ? userData?.state?._id : ""
      );
      formik.setFieldValue("city", userData.city ? userData.city : "");
      formik.setFieldValue("aboutMe", userData.aboutMe ? userData.aboutMe : "");
    }
  }, [userData, userid]);

  const formik = useFormik({
    initialValues: {
      profileCreatedFor: "",
      gender: "",
      city: "",
      dateOfBirth: null,
      age: "",
      height: {
        feet: "",
        inches: "",
      },
      weight: "",
      bodyType: "",
      complexion: "",
      smoking: "",
      drinking: "",
      eatingHabits: "",
      specialCase: "",
      state: "",
      city: "",
      aboutMe: "",
      bioData: "",
    },
    validationSchema: Yup.object({
      gender: Yup.string().required("Gender is a required"),
      dateOfBirth: Yup.string().required("Date Of birth is a required"),
      height: Yup.object().shape({
        feet: Yup.string().required("Feet is a required"),
        inches: Yup.string().required("Inches is a required"),
      }),
      weight: Yup.string().required("Weight is a required"),
      bodyType: Yup.string().required("Body type is a required"),
      complexion: Yup.string().required("Complexion is a required"),
      smoking: Yup.string().required("Smoking is a required"),
      drinking: Yup.string().required("Drinking is a required"),
      eatingHabits: Yup.string().required("Eating habits is a required"),
      specialCase: Yup.string().required("Special case is a required"),
      state: Yup.string().required("State is a required"),
      city: Yup.string().required("City is a required"),
      aboutMe: Yup.string().required("About me is a required"),
    }),
    onSubmit: (values) => {
      const birthday = new Date(values.dateOfBirth);
      const now = new Date();
      const ageInYears = Math.abs(now.getFullYear() - birthday.getFullYear());
      const temp_obj = {
        ...values,
        age: ageInYears,
        navigate: navigate,
        id: userid,
      };
      dispatch(update(temp_obj));
    },
  });

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Advanced Bio</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    Profile Created For <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="profileCreatedFor"
                    value={formik.values.profileCreatedFor}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Profile Created For</option>
                    {profilecreated.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.profileCreatedFor &&
                    formik.touched.profileCreatedFor && (
                      <p className="text-danger">
                        {formik.errors.profileCreatedFor}
                      </p>
                    )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Gender <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="gender"
                    value={formik.values.gender}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select your Gender</option>
                    <option value={"male"}>Male</option>
                    <option value={"female"}>Female</option>
                  </select>
                  {formik.errors.gender && formik.touched.gender && (
                    <p className="text-danger">{formik.errors.gender}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Date of birth <span className="text-danger">*</span>
                  </label>
                  <CustomDate
                    name="dateOfBirth"
                    selected={
                      (formik.values.dateOfBirth &&
                        new Date(formik.values.dateOfBirth)) ||
                      null
                    }
                    onChange={(val) => {
                      formik.setFieldValue("dateOfBirth", val);
                    }}
                  />
                  {/* <ReactDatePicker
                    name="dateOfBirth"
                    className="form-control"
                    maxDate={new Date()}
                    selected={
                      (formik.values.dateOfBirth &&
                        new Date(formik.values.dateOfBirth)) ||
                      null
                    }
                    placeholderText="DD/MM/YYYY"
                    onChange={(val) => {
                      formik.setFieldValue("dateOfBirth", val);
                    }}
                  /> */}
                  {formik.errors.dateOfBirth && formik.touched.dateOfBirth && (
                    <p className="text-danger">{formik.errors.dateOfBirth}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6 form-group">
                <div className="form-group">
                  <label>
                    Height <span className="text-danger">*</span>
                  </label>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          name="height.feet"
                          value={formik?.values.height.feet}
                          onChange={formik?.handleChange}
                          aria-describedby="basic-addon"
                        />
                        <span className="input-group-text" id="basic-addon">
                          Feet
                        </span>
                      </div>
                      {formik?.errors?.height?.feet &&
                        formik?.touched?.height?.feet && (
                          <p className="text-danger">
                            {formik?.errors.height.feet}
                          </p>
                        )}
                    </div>
                    <div className="col-md-6">
                      <div className="input-group ">
                        <input
                          type="text"
                          className="form-control"
                          aria-describedby="basic-addon2"
                          name="height.inches"
                          value={formik.values.height.inches}
                          onChange={formik.handleChange}
                        />
                        <span className="input-group-text" id="basic-addon2">
                          Inches
                        </span>
                      </div>
                      {formik?.errors?.height?.inches &&
                        formik?.touched?.height?.inches && (
                          <p className="text-danger">
                            {formik?.errors.height.inches}
                          </p>
                        )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Weight (Kg) <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="weight"
                    value={formik.values.weight}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.weight && formik.touched.weight && (
                    <p className="text-danger">{formik.errors.weight}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Body Type <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="bodyType"
                    value={formik.values.bodyType}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Body Type</option>
                    {bodyTypeList.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.bodyType && formik.touched.bodyType && (
                    <p className="text-danger">{formik.errors.bodyType}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Complexion <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="complexion"
                    value={formik.values.complexion}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Complexion</option>
                    {complexionList.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.complexion && formik.touched.complexion && (
                    <p className="text-danger">{formik.errors.complexion}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Is Smoking <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="smoking"
                    value={formik.values.smoking}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Is Smoking</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Moderate">Moderate</option>
                  </select>
                  {formik.errors.smoking && formik.touched.smoking && (
                    <p className="text-danger">{formik.errors.smoking}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Is Drinking <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="drinking"
                    value={formik.values.drinking}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Is Drinking</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Occasionally">Occasionally</option>
                  </select>
                  {formik.errors.drinking && formik.touched.drinking && (
                    <p className="text-danger">{formik.errors.drinking}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Eating Habit <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="eatingHabits"
                    value={formik.values.eatingHabits}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Eating Habit</option>
                    {eatingHabitList.map((item, i) => {
                      return (
                        <option value={item.id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.eatingHabits &&
                    formik.touched.eatingHabits && (
                      <p className="text-danger">
                        {formik.errors.eatingHabits}
                      </p>
                    )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    Special Case <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="specialCase"
                    value={formik.values.specialCase}
                    onChange={formik.handleChange}
                  >
                    <option value="">Select Special Case</option>
                    {specialcase.map((item, i) => {
                      return (
                        <option value={item._id} key={i}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.errors.specialCase && formik.touched.specialCase && (
                    <p className="text-danger">{formik.errors.specialCase}</p>
                  )}
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    State <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control"
                    name="state"
                    value={formik.values.state}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Any</option>
                    {state?.length > 0 &&
                      state?.map((value, index) => {
                        return (
                          <option value={value._id} key={index}>
                            {value.name}
                          </option>
                        );
                      })}
                  </select>
                  {formik.errors.state && formik.touched.state && (
                    <p className="text-danger">{formik.errors.state}</p>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>
                    City <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="city"
                    name="city"
                    value={formik.values.city}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.city && formik.touched.city && (
                    <p className="text-danger">{formik.errors.city}</p>
                  )}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <label>
                    About me <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="aboutMe"
                    value={formik.values.aboutMe}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.aboutMe && formik.touched.aboutMe && (
                    <p className="text-danger">{formik.errors.aboutMe}</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Update
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default AdvancedBio;
