import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { useEffect } from "react";
import { create, get, remove } from "../../../store/payment";
import { update } from "../../../store/user";
import PageLoader from "../../../components/PageLoader/PageLoader";
import * as Yup from "yup";
import swal from "sweetalert";
import moment from "moment";

var plan = {
  "3months": "Gold",
  "6months": "Diamond",
  "12months": "Platinum",
  tillumarry: "Till U Marry",
};

var tillValid = {
  "3months": 3,
  "6months": 6,
  "12months": 12,
  tillumarry: "Till U Marry",
};

function Payment({ userData }) {
  const { userid } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading, list } = useSelector((state) => state?.paymentReducer);

  useEffect(() => {
    if (userid) {
      dispatch(
        get({
          page: 1,
          user: userid,
        })
      );
    }
  }, [userid]);

  const getValidTill = (date, months) => {
    if (months === "Till U Marry") {
      return null;
    }
    if (date) {
      var newDate = moment(new Date(date)).add(months, "M");
      return new Date(newDate);
    } else {
      return null;
    }
  };

  const formik = useFormik({
    initialValues: {
      type: "",
    },
    validationSchema: Yup.object({
      type: Yup.string().required("required"),
    }),
    onSubmit: (values) => {
      const temp_obj = {
        type: values.type,
        user: userid,
        planName: plan[values.type],
        start: new Date(),
        end: getValidTill(new Date(), tillValid[values.type]),
      };
      dispatch(create(temp_obj))
        .unwrap()
        .then((resp) => {
          if (resp.status) {
            formik.resetForm();
            dispatch(
              update({
                lastPlan: resp.data._id,
                planStatus: "premium",
                id: userid,
                msg: true,
              })
            );
            dispatch(
              get({
                page: 1,
                user: userid,
              })
            );
          }
        })
        .catch((error) => {});
    },
  });

  const handleDelete = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(remove(id))
          .unwrap()
          .then((resp) => {
            if (resp.status) {
              dispatch(
                get({
                  page: 1,
                  user: userid,
                })
              );
            }
          })
          .catch((error) => {});
      }
    });
  };

  return (
    <>
      {loading && <PageLoader />}
      <div className="card card-default">
        <div className="card-header">
          <div className="card-title">Payment</div>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="row" style={{ padding: "0 0 15px 0" }}>
              <div className="col-md-5">
                <div className="form-group">
                  <label>
                    Plan Type <span className="text-danger">*</span>
                  </label>

                  <select
                    className="form-control"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  >
                    <option value={""}>Select Plan Type</option>
                    {Object.entries(plan).map(([key, value]) => {
                      return <option value={key}>{value}</option>;
                    })}
                  </select>

                  {formik.touched.type && formik.errors.type ? (
                    <div style={{ color: "red" }}>{formik.errors.type}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-7">
                <div className="table-responsive bootgrid">
                  {list?.length > 0 && (
                    <table className="table" id="bootgrid-command">
                      <thead>
                        <tr>
                          <th>PLAN TYPE</th>
                          {/* <th
                          data-column-id="commands"
                          data-formatter="commands"
                          data-sortable="false"
                        >
                          ACTION
                        </th> */}
                        </tr>
                      </thead>

                      {list?.map((value, index) => {
                        return (
                          <tbody key={index}>
                            <tr>
                              <td>{value?.planName}</td>
                              {/* <td>
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger command-delete"
                                  data-row-id="10253"
                                  onClick={() => handleDelete(value?._id)}
                                >
                                  <em className="fa fa-trash fa-fw"></em>
                                </button>
                              </td> */}
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                className="btn btn-labeled btn-success mb-2"
                type="submit"
                style={{ fontSize: "17px" }}
              >
                <span className="btn-label">
                  <i className="fa fa-check"></i>
                </span>
                Add
              </button>
              <span style={{ padding: "0 10px" }}>or</span>
              <button
                className="btn btn-labeled btn-secondary mb-2"
                type="button"
                style={{ fontSize: "17px" }}
                onClick={() => navigate("/admin/users/1")}
              >
                <span className="btn-label">
                  <i className="fa fa-times"></i>
                </span>
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default Payment;
