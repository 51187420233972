import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import swal from "sweetalert";
import PageLoader from "../../../components/PageLoader/PageLoader";
import Paginate from "../../../components/pagination/paginate";
import { remove, get } from "../../../store/payment";

function TableFilter() {
  const dispatch = useDispatch();
  const params = useParams();
  const [search, setSearch] = useState("");
  const { list, paginate, loading } = useSelector(
    (state) => state?.paymentReducer
  );
  const [page, setPage] = useState(params.page);

  useEffect(() => {
    dispatch(get({ page: page }));
  }, [page]);

  const handleDelete = (id) => {
    swal({
      title: "Are you sure you want to delete?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        dispatch(remove(id));
      }
    });
  };

  const onSearch = (data) => {
    dispatch(
      get({
        page: page,
        search: data,
      })
    );
  };

  return (
    <>
      {loading && <PageLoader />}

      <div className="card card-default dataTables_wrapper mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-8">
              <div className="form-group">
                <label>Search</label>
                <input
                  type="text"
                  className="form-control"
                  value={search}
                  placeholder="Search by candidateId, name, age, email, mobileNumber, gender, city"
                  onChange={(e) => {
                    setSearch(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-4">
              <button
                className="btn btn-primary mr-3 mt-4"
                type="submit"
                style={{ fontSize: "17px" }}
                onClick={() => onSearch(search)}
              >
                Search
              </button>
              <button
                className="btn btn-secondary mt-4"
                type="submit"
                style={{ fontSize: "17px" }}
                onClick={() => {
                  setSearch("");
                  onSearch("");
                }}
              >
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card card-default dataTables_wrapper">
        <div className="card-body">
          {list?.length > 0 ? (
            <div className="table-responsive bootgrid scroll-delivery">
              <table className="table" id="bootgrid-command">
                <thead>
                  <tr>
                    <th>USER</th>
                    <th>PLAN TYPE</th>
                    {/* <th
                      data-column-id="commands"
                      data-formatter="commands"
                      data-sortable="false"
                    >
                      ACTION
                    </th> */}
                  </tr>
                </thead>
                {list?.map((value, index) => {
                  return (
                    <tbody key={index}>
                      <tr>
                        <td>
                          <div className="row">
                            <div className="col-md-12">
                              <h4>{value?.user?.name}</h4>
                              <div>
                                <strong>CandidateId: </strong>
                                {value?.user?.candidateId}
                              </div>
                              <div>
                                <strong>Email: </strong>
                                {value?.user?.email}
                              </div>
                              <div>
                                <strong>Mobile Number: </strong>
                                {value?.user?.mobileNumber}
                              </div>

                              <div>
                                <strong>Gender: </strong>
                                {value?.user?.gender}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>{value.planName}</td>
                        {/* <td style={{ width: 120 }}>
                          <button
                            type="button"
                            className="btn btn-sm btn-danger command-delete"
                            data-row-id="10253"
                            onClick={() => handleDelete(value?._id)}
                          >
                            <em className="fa fa-trash fa-fw"></em>
                          </button>
                        </td> */}
                      </tr>
                    </tbody>
                  );
                })}
              </table>
              {paginate && (
                <Paginate paginate={paginate} page={page} setPage={setPage} />
              )}
            </div>
          ) : (
            <div className="text-center">No records found</div>
          )}
        </div>
      </div>
    </>
  );
}

export default TableFilter;
