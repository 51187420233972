// ** Toolkit imports
import { configureStore } from "@reduxjs/toolkit";

// ** Reducers
import authReducer from "../store/auth/index";
import userReducer from "../store/user/index";
import reviewReducer from "../store/review/index";
import profileCreatedReducer from "../store/profileCreated/index";
import specialCaseReducer from "../store/specialCase/index";
import stateReducer from "../store/state/index";
import religionReducer from "../store/religion/index";
import casteReducer from "../store/caste/index";
import languageReducer from "../store/language/index";
import qualificationReducer from "../store/qualification/index";
import occupationReducer from "../store/occupation/index";
import successStorieReducer from "../store/successStorie/index";
import photoGalleryReducer from "../store/photoGallery/index";
import scheduleMeetingReducer from "../store/scheduleMeeting/index";
import fileUploadReducer from "../store/fileUpload/index";
import paymentReducer from "../store/payment/index";
import dashboardReducer from "../store/dashboard/index";
import sidebarReducer from "../store/sidebar";
import feedbackReducer from "../store/feedback/index";

export const store = configureStore({
  reducer: {
    authReducer,
    sidebarReducer,
    userReducer,
    reviewReducer,
    profileCreatedReducer,
    specialCaseReducer,
    stateReducer,
    religionReducer,
    casteReducer,
    languageReducer,
    qualificationReducer,
    occupationReducer,
    successStorieReducer,
    photoGalleryReducer,
    scheduleMeetingReducer,
    fileUploadReducer,
    paymentReducer,
    dashboardReducer,
    feedbackReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
