import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const get = createAsyncThunk("photoGallery/get", async (page) => {
  try {
    const response = await httpService.get(`/api/photo-gallery?page=${page}`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const all = createAsyncThunk("photoGallery/all", async () => {
  try {
    const response = await httpService.get(`/api/photo-gallery/all`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const create = createAsyncThunk(
  "photoGallery/create",
  async (params) => {
    try {
      const response = await httpService.post("/api/photo-gallery", {}, params);
      if (response?.data) {
        toast.success("Created successfully");
        params.navigate("/admin/photo-gallery/1");
      }
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const update = createAsyncThunk(
  "photoGallery/update",
  async (params) => {
    try {
      const response = await httpService.put(
        `/api/photo-gallery/${params.id}`,
        {},
        params
      );
      if (response?.data) {
        toast.success("Updated successfully");
        params.navigate("/admin/photo-gallery/1");
      }
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const getById = createAsyncThunk("photoGallery/getById", async (id) => {
  try {
    const response = await httpService.get(`/api/photo-gallery/${id}`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const remove = createAsyncThunk("photoGallery/delete", async (id) => {
  try {
    const response = await httpService.delete(`/api/photo-gallery/${id}`);
    if (response?.data) {
      response.data.id = id;
      toast.success("Deleted successfully");
    }
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const photoGallerySlice = createSlice({
  name: "PhotoGallery",
  initialState: {
    list: [],
    loading: false,
    paginate: "",
    data: "",
    isdeleted: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(get.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(get.fulfilled), (state, action) => {
      state.list = action.payload?.data?.docs;
      const paginate = {
        hasNextPage: action?.payload?.data?.hasNextPage,
        hasPrevPage: action?.payload?.data?.hasPrevPage,
        limit: action?.payload?.data?.limit,
        nextPage: action?.payload?.data?.nextPage,
        page: action?.payload?.data?.page,
        pagingCounter: action?.payload?.data?.pagingCounter,
        prevPage: action?.payload?.data?.prevPage,
        totalDocs: action?.payload?.data?.totalDocs,
        totalPages: action?.payload?.data?.totalPages,
      };
      state.paginate = paginate;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(get.rejected), (state, action) => {
      state.loading = false;
    });

    builder.addMatcher(isAllOf(all.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(all.fulfilled), (state, action) => {
      state.list = action.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(all.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(create.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(create.fulfilled), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(create.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(update.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(update.fulfilled), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(update.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(remove.pending), (state, action) => {
      state.loading = true;
      state.isdeleted = false;
    });
    builder.addMatcher(isAllOf(remove.fulfilled), (state, action) => {
      state.isdeleted = true;
      state.list?.splice(
        state.list?.findIndex((data) => data?._id === action.payload.id),
        1
      );
      state.loading = false;
    });
    builder.addMatcher(isAllOf(remove.rejected), (state) => {
      state.isdeleted = false;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(getById.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(getById.fulfilled), (state, action) => {
      state.data = action?.payload?.data;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(getById.rejected), (state) => {
      state.loading = false;
    });
  },
});

export default photoGallerySlice.reducer;
