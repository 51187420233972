import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { create } from "../../../store/feedback/index";
import { all } from "../../../store/user/index";
import PageLoader from "../../../components/PageLoader/PageLoader";
import { MultiSelect } from "react-multi-select-component";
import { useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

function AddFeedback() {
  const dispatch = useDispatch();
  const {  list : userList } = useSelector((state) => state?.userReducer);
  const { loading } = useSelector((state) => state?.feedbackReducer);
  const [editorValues, setValues] = useState("");

  useEffect(() => {
    dispatch(all());
  }, [])
  

  const formik = useFormik({
    initialValues: {
      user: [],
      subject: "",
      html: "",
    },
    validationSchema: Yup.object({
      user: Yup.array().min(1, "Required")
      .required("Required")
      .nullable(),
      subject: Yup.string().required("Required"),
      html: Yup.string().required("Required"),
    }),
    onSubmit: (values) => {
        dispatch(create(values)).unwrap()
        .then((data) => {
          if (data?.status) {
            formik.resetForm()
            setValues("")
          }
        });
    },
  });

  const handleChange = selectedOption => {
    formik.setFieldValue("user", selectedOption);
  };

  const onEditorStateChange = (editorState) => {
    formik.setFieldValue(
      "html",
      draftToHtml(convertToRaw(editorState.getCurrentContent()))
    );
    setValues(editorState);
  };


  return (
    <>
      {loading && <PageLoader />}
        <div className="row">
        <div className="col-md-9 m-auto">
        <div className="card card-default">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      Users <span className="text-danger">*</span>
                      </label>
                        <MultiSelect
                        name="user"
                        value={formik.values.user}
                        onChange={handleChange}
                        options={userList.map((option) => {
                          return {
                            label: option.email,
                            value : option.email,
                        }})}
                        isClearable={true}
                      />
                    {formik.touched.user && formik.errors.user ? (
                      <div style={{ color: "red" }}>{formik.errors.user}</div>
                    ) : null}
                  </div>
                  </div>
                  <div className="col-md-12">
                  <div className="form-group">
                    <label>
                    Subject <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      name="subject"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <div style={{ color: "red" }}>{formik.errors.subject}</div>
                    ) : null}
                  </div>
                  </div>
                  <div className="col-md-12">
                  <div className="form-group">
                    <label>
                      HTML <span className="text-danger">*</span>
                      </label>
                      <Editor
                          editorClassName="form-control"
                          editorState={editorValues}
                          onEditorStateChange={onEditorStateChange}
                          editorStyle={{ height: 300 }}
                        />
                    {/* <textarea
                      className="form-control"
                      name="html"
                      type="text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                        value={formik.values.html}
                        rows={15}
                    ></textarea> */}
                    {formik.touched.html && formik.errors.html ? (
                      <div style={{ color: "red" }}>{formik.errors.html}</div>
                    ) : null}
                  </div>
                </div>
              </div>

              <div>
                <button
                  className="btn btn-labeled btn-success mb-2"
                  type="submit"
                  style={{ fontSize: "17px" }}
                >
                  <span className="btn-label">
                    <i className="fa fa-check"></i>
                  </span>
                  Submit
                </button>
              </div>
            </form>
          </div>
          </div>
          </div>
      </div>
    </>
  );
}
export default AddFeedback;
