import TableFilter from "./tableFilter";

function ScheduleMeeting() {
  return (
    <>
      <div className="content-wrapper">
        <div
          className="content-heading d-flex
                          justify-content-between"
        >
          <div>Schedule Meeting</div>
        </div>
        <TableFilter />
      </div>
    </>
  );
}

export default ScheduleMeeting;
