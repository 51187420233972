import { createSlice, createAsyncThunk, isAllOf } from "@reduxjs/toolkit";

// ** Axios Imports
import { toast } from "react-toastify";
import httpService from "../../common/http.service";

export const getReview = createAsyncThunk("review/getReview", async (page) => {
  try {
    const response = await httpService.get(`/api/review?page=${page}`);
    return await response.data;
  } catch (error) {
    toast.error(error?.message);
  }
});

export const createReview = createAsyncThunk(
  "review/createReview",
  async (params) => {
    try {
      const response = await httpService.post("/api/review", {}, params);
      if (response?.data) {
        toast.success("Created successfully");
        params.navigate("/admin/review/1");
      }
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const deleteReview = createAsyncThunk(
  "review/deleteReview",
  async (id) => {
    try {
      const response = await httpService.delete(`/api/review/${id}`);
      if (response?.data) {
        response.data.id = id;
        toast.success("Deleted successfully");
      }
      return await response.data;
    } catch (error) {
      toast.error(error?.message);
    }
  }
);

export const reviewSlice = createSlice({
  name: "Review",
  initialState: {
    reviews: [],
    loading: false,
    paginate: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(isAllOf(getReview.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(getReview.fulfilled), (state, action) => {
      state.reviews = action.payload?.data?.docs;
      const paginate = {
        hasNextPage: action?.payload?.data?.hasNextPage,
        hasPrevPage: action?.payload?.data?.hasPrevPage,
        limit: action?.payload?.data?.limit,
        nextPage: action?.payload?.data?.nextPage,
        page: action?.payload?.data?.page,
        pagingCounter: action?.payload?.data?.pagingCounter,
        prevPage: action?.payload?.data?.prevPage,
        totalDocs: action?.payload?.data?.totalDocs,
        totalPages: action?.payload?.data?.totalPages,
      };
      state.paginate = paginate;
      state.loading = false;
    });
    builder.addMatcher(isAllOf(getReview.rejected), (state, action) => {
      state.loading = false;
    });
    builder.addMatcher(isAllOf(deleteReview.pending), (state, action) => {
      state.loading = true;
    });
    builder.addMatcher(isAllOf(deleteReview.fulfilled), (state, action) => {
      state.reviews?.splice(
        state.reviews?.findIndex((data) => data?._id === action.payload.id),
        1
      );
      state.loading = false;
    });
    builder.addMatcher(isAllOf(deleteReview.rejected), (state) => {
      state.loading = false;
    });
  },
});

export default reviewSlice.reducer;
